import React from 'react';
import Man1 from 'images/men/Marc.svg';
import Man2 from 'images/men/Fabien.svg';
import Man3 from 'images/men/Richard.svg';
import { gilbertOnly } from 'components/common/data/doctors';

import lessonsData from './programData.json';

export const scientistData = {
  doctor: gilbertOnly,
  title: 'Le directeur scientifique des thérapies digitales',
  subtitle: '',
  body: "Le Dr Gilbert Bou Jaoudé est un docteur en médecine spécialisé en sexologie depuis plus de 25 ans. Cet ancien président de la Société Francophone de Médecine Sexuelle fait partie de l'aventure charles.co et mia.co et est aussi très présent dans les médias. Son objectif ? Faire de la santé sexuelle, une santé reconnue, sans tabous pour offrir de véritables solutions aux patients et patientes qui rencontrent des difficultés sexuelles. C'est aussi pour cela qu'il a initié et fait partie de nombreuses recherches et études sur la sexualité. Son approche est médicale, complète, douce et bienveillante.",
};

export const metaData = {
  title: 'Maîtriser son éjaculation avec le programme TIME | Charles.co',
  description: 'Maîtrisez l’éjaculation rapide avec le programme TIME développé par des sexologues. 11 coaching vidéos, 13 exercices physiques et mentaux. Assistance illimitée.',
};

export const topSection = {
  h1: 'Programme TIME',
  programSlug: 'time-program',
  ctaLabel: 'Tester gratuitement la première étape',
  borderIcon: 'border-sex-icon.svg',
  subtitle: (
    <div className="pathology-subtitle">
      Thérapie Intensive de Maîtrise de l'Éjaculation
    </div>
  ),
  video: {
    titleDisplay: false,
    filename: 'videos/thumbnails/thumbnail-time-program.jpg',
    alt: 'programme-time',
    title: 'programme time',
    video:
      'https://cdn.jwplayer.com/players/nhdbCoHz-KdT3I1iC.html',
  },
  reinsurance: [
    {
      text: '93% des patients interrogés constatent une amélioration durable',
      icon: 'icon-med-confiance-experts-square.svg',
      alt: 'thumbs-up',
      title: 'thumbs up',
    },
    {
      text: "90 jours de thérapie complète issue de 25 ans d'expérience en sexologie et médecine sexuelle",
      icon: 'icon-med-videoconference-square.svg',
      alt: 'med-videoconference-icon',
      title: 'med videoconference icon',
    },
    {
      text: 'À votre rythme et sans engagement : vous pouvez arrêter et reprendre le programme quand vous le souhaitez',
      icon: 'icon-large-rendez-vous-confirme-square.svg',
      alt: 'rdv-confirmation-icon',
      title: 'rdv confirmation icon',
    },
    {
      text: 'Accompagnement par notre équipe tout au long du programme',
      icon: 'icon-med-specialistes-fr-square.svg',
      alt: 'med-specialistes-icon',
      title: 'med specialistes icon',
    },
  ],
};

export const about = {
  h2: 'A propos du programme TIME',
  accordion: [
    {
      title: 'Que vais-je trouver dans le programme TIME ?',
      text: 'Le programme TIME (Thérapie Intensive de Maîtrise de L\'Éjaculation) est un programme complet de 90 jours, pendant lequel vous allez apprendre 13 techniques physiques et mentales pour mieux maîtriser votre éjaculation. Le programme TIME se compose de 11 vidéos de coaching en ligne, rythmant 9 étapes de 10 jours de travail spécifique et personnalisé. Tout au long du programme TIME, vous bénéficierez d’un accompagnement quotidien à la demande de nos coachs par téléphone et par message. Ce programme unique et novateur a été conçu par le Docteur Gilbert Bou Jaoudé, médecin sexologue français, après avoir mené des tests depuis 25 ans sur les patients de son cabinet, et la plateforme charles.co, plateforme leader en Europe de la sexologie en ligne.',
    },
    {
      title: 'À qui s’adresse le programme TIME ?',
      text: 'Le programme TIME s’adresse à tous les hommes qui souhaitent prendre le contrôle de leur éjaculation, que ce soit parce qu’ils ont l’impression de ne pas durer assez longtemps au lit, parce qu’ils souffrent d’éjaculation précoce ou rapide, ou simplement car ils souhaitent contrôler encore mieux et pouvoir donner plus de plaisir à leur partenaire, sans limitation ni privation.',
    },
    {
      title: 'Y a-t-il un suivi personnalisé pendant la durée du programme ?',
      text: "Pendant tout le programme, nos coachs sont disponibles 5 jours sur 7 pour vous donner des explications, des conseils et vous remotiver. Vous pouvez parfois avoir ce sentiments de démotivation, c'est normal et ce n'est pas grave. Si vous avez de la peine à avancer, prenez le réflexe de décrocher votre téléphone et d'appeler l'assistance TIME. Vous ne serez jamais seul !",
    },
  ],
  definitionLabel: 'Docteur Glbert Bou Jaoudé',
  definition: (
    <div>
      "Le sujet est tellement tabou, que peu de personnes en parlent et de nombreux hommes se retrouvent au pied du mur ou n’imagineraient même pas qu’il existe des solutions pour s’en sortir. Pourtant, il en existe bien."
    </div>
  ),
};

export const whyItWorks = {
  title: 'Pourquoi est-ce efficace ?',
  text: 'Le programme TIME est le seul programme complet conçu par des médecins sexologues qui vous permet au bout de 3 mois de :',
  list: [
    {
      fullName: 'Maitriser votre physique',
      description: 'Vous allez apprendre les techniques qui permettent d’obtenir, avec de la pratique, un contrôle durable sur tous les muscles responsables du réflexe éjaculatoire. Au fil des étapes vous ferez des exercices physiques de plus en plus précis et avancés qui seront directement applicables lors des rapports.',
      pictures: {
        regular: 'new-images/yoga-man.jpg',
      },
    },
    {
      fullName: 'Maitriser votre mental',
      description: 'La dimension psychologique est extrêmement importante dans l’éjaculation rapide. Ce programme vous permettra d’apprendre et d’appliquer des techniques mentales vous permettant de maîtriser votre anxiété, votre stress et de vivre sereinement vos moments de plaisir.',
      pictures: {
        regular: 'new-images/yoga-old-man.jpg',
      },
    },
    {
      fullName: 'À votre rythme et à la carte',
      description: 'Le Programme s’adapte à vous. Vous pouvez suivre le rythme conseillé (une étape tous les dix jours) mais aussi faire des pauses et effectuer le programme à votre rythme, en fonction de vos envies et de vos contraintes. Tout au long du programme, nos équipes sont là pour répondre à vos questions et vous assister si besoin.',
      pictures: {
        regular: 'new-images/gilbert-why-it-works.jpeg',
      },
    },
  ],
};

export const testimonies = {
  title: 'Nos patients témoignent',
  subtitle: 'Et ce sont eux qui en parlent le mieux',
  content: [
    {
      text: "Pour tous ceux qui souffrent d'éjaculation précoce, n'hésitez pas à vous engager dans le programme TIME. C'est un programme qui peut paraître long, mais qui est très positif au niveau des résultats.",
      info: 'Romain, 27 ans',
    },
    {
      text: "Une solution moderne et efficace : on reçoit des exercices à réaliser sérieusement, et des petites astuces qui s'avèrent efficaces, le tout expliqué clairement en vidéo. Le fait de discuter par téléphone rend la démarche moins gênante que la prise d un rendez vous \"en vrai\".",
      info: 'Marc, 42 ans',
    },
    {
      text: "Le docteur est clair dans les vidéos et je me suis beaucoup aidé des feuilles d’exercices, petit à petit, je vois les résultats, j’ai hâte d’arriver à la fin ! Je pense que je vais continuer les exercices même après le programme.",
      info: 'Antoine, 56 ans',
    },
  ],
};

export const sections = {
  title: ' ',
  videoList: [],
  faq: [
    {
      title: 'Que vais-je trouver dans le programme TIME ?',
      text: (
        <div>
          Le programme TIME (Thérapie Intensive de Maîtrise de L'Éjaculation) est un programme complet de 90 jours, pendant lequel vous allez apprendre 13 techniques physiques et mentales pour mieux maîtriser votre éjaculation. Le programme TIME se compose de 11 vidéos de coaching en ligne, rythmant 9 étapes de 10 jours de travail spécifique et personnalisé. Tout au long du programme TIME, vous bénéficierez d’un accompagnement quotidien à la demande de nos coachs par téléphone et par message. Ce programme unique et novateur a été conçu par le Docteur Gilbert Bou Jaoudé, médecin sexologue français, après avoir mené des tests depuis 25 ans sur les patients de son cabinet, et la plateforme charles.co, plateforme leader en Europe de la sexologie en ligne.
        </div>
      ),
    },
    {
      title: 'A qui s’adresse le programme TIME ?',
      text: (
        <div>
          Le programme TIME s’adresse à tous les hommes qui souhaitent prendre le contrôle de leur éjaculation, que ce soit parce qu’ils ont l’impression de ne pas durer assez longtemps au lit, parce qu’ils souffrent d’éjaculation précoce ou rapide, ou simplement car ils souhaitent contrôler encore mieux et pouvoir donner plus de plaisir à leur partenaire, sans limitation ni privation.
        </div>
      ),
    },
    {
      title: 'Pourquoi le programme TIME dure 90 jours ?',
      text: (
        <div>
          Les 9 étapes du programme TIME se déroulent sur 90 jours car c’est la durée optimale selon notre expérience pour pouvoir améliorer significativement et durablement un réflexe aussi profond et naturel que celui l’éjaculation. Ces 90 jours sont nécessaires pour assimiler et maîtriser les 13 techniques mentales et physiques que vous allez apprendre et qui vont vous permettre d’obtenir un contrôle de votre éjaculation.
        </div>
      ),
    },
    {
      title: 'Combien de temps dois-je y consacrer ?',
      text: (
        <div>
          Pour garantir une efficacité maximale du programme TIME, il est préconisé de réserver 15 minutes pour une séance d’exercices et de faire au moins 7 séances d’exercices dans chaque étape de 10 jours. Cette discipline dans la fréquence des séances vous permettra d’obtenir des résultats significatifs et durables sur votre éjaculation.
        </div>
      ),
    },
    {
      title: 'Quels résultats attendre du programme TIME ?',
      text: (
        <div>
          Au bout des 90 jours, vous serez en mesure de contrôler votre éjaculation dans toutes les circonstances, sans devoir vous contraindre ou vous priver de ce que vous aimez pendant l’acte ; et vous aurez en plus des clefs pour donner et prendre plus de plaisir avec votre partenaire.
        </div>
      ),
    },
    {
      title: 'Que se passe-t-il si je n’y arrive pas ou si je me décourage ?',
      text: (
        <div>
          L’objectif de toute l’équipe du programme TIME est de vous aider à obtenir un contrôle total et durable de votre éjaculation. Tout au long du programme, vous bénéficierez de l’assistance de nos coachs qui seront disponibles 5 jours sur 7 pour vous donner des explications et des conseils sur vos exercices afin de vous motiver.
        </div>
      ),
    },
    {
      title: 'En quoi consiste l’assistance par téléphone et par message ?',
      text: (
        <div>
          Les membres du programme TIME ont accès pendant les 90 jours du Programme a une assistance téléphonique et par messagerie, en lien avec des coachs spécialement formés par le Docteur Bou Jaoudé. Ils seront là pour vous accompagner dans la réussite de la thérapie et disponibles tous les jours du lundi au vendredi de 9h30 à 18h30.
        </div>
      ),
    },
    {
      title: 'Comment savoir quels exercices faire à chaque étape ?',
      text: (
        <div>
          Au début de chaque étape, vous pourrez visionner à volonté la vidéo explicative des exercices de l’étape. Vous recevrez également par mail au même moment un programme détaillé d’exercices de l’étape. Et si vous avez encore des questions, l’assistance est à votre disposition pour y répondre.
        </div>
      ),
    },
    {
      title: 'Comment passer d’une étape à la suivante ?',
      text: (
        <div>
          Pour valider une étape du programme TIME, vous devez faire les exercices spécifiques de l’étape pendant 10 jours. À la fin de la période de 10 jours, vous ferez une auto-évaluation de vos progrès sur le site. Cette évaluation est essentielle car elle permet à toute l’équipe du programme TIME de mesurer vos progrès afin de vous aider au mieux à réussir. Une fois l’évaluation complétée, et si les exercices de l’étape sont maîtrisés, vous pourrez passer à l’étape suivante.
        </div>
      ),
    },
    {
      title: 'Pourquoi avoir choisi un médecin sexologue ?',
      text: (
        <div>
          Afin de garantir le sérieux et l’efficacité de la méthode, Charles.co, plateforme leader de la sexologie en ligne en Europe, s’est appuyé sur le Docteur Gilbert Bou Jaoudé, médecin sexologue depuis 25 ans, ancien président la Société Française de Médecine Sexuelle, pour créer ce programme unique en son genre.
        </div>
      ),
    },
    {
      title: 'Comment puis-je régler le programme TIME ?',
      text: (
        <div>
          Le règlement du programme TIME peut s’effectuer en 1 ou 3 fois par carte bancaire. La plateforme Charles.co utilise le prestataire de paiement Sécurisé MANGOPAY. Le paiement apparaîtra de façon discrète sur votre relevé bancaire : MGP*CHARLESAS.
        </div>
      ),
    },
  ],
};

export default {
  sections,
  topSection,
  testimonies,
  about,
  whyItWorks,
  scientistData,
  metaData,
  lessonsData,
};
