import React from 'react';
import data from './data.js';
import Classic from 'components/Program/Template/Classic';
import './styles.sass';

const IndexPage = () => (
  <Classic programData={data} />
);

export default IndexPage;
